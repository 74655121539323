<template>
    <div class="resources-content">
        <div class="select-wrapper">
            <div style="display: flex;">
                <div class="select-left">                 
                     <div class="select">
                         <span class="text">院校考生分类：</span>
                        <el-select v-model="examinee_id" placeholder="请选择" style="width: 200px" clearable @change="examineeCha">
                            <el-option v-for="item in examineeList" :key="item.testee_category_id" :label="item.name" :value="item.testee_category_id"></el-option>
                        </el-select>
                    </div>
                </div>
            </div>
        </div>
        <div class="select-wrapper">
            <!-- <div style="display: flex;">
                <div class="select-left">
                    <span class="text">考试时间选择：</span>
                    <div class="block" style="width: 300px">
                        <el-date-picker
                            v-model="testTime_id"
                            type="datetimerange"
                            range-separator="至"
                            start-placeholder="开始日期"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            end-placeholder="结束日期" @change="time">
                        </el-date-picker>
                    </div>
                </div>             
            </div> -->
            <div class="type-wrapper" style="margin-left: 0;">
            <div class="type-name" style="margin-right: 10px;">资源类型：</div>
                <div class="type-list" >
                    <div class="type-item" :class="{current:typeCurrent === 0}" @click="toggleTypeCur(0)">全部</div>
                        <div class="type-item" :class="{current:typeCurrent === resourcesItem.grade_id}"
                            v-for="resourcesItem in resourcesTypeList"
                            :key="resourcesItem.grade_id"
                            @click="toggleTypeCur(resourcesItem.grade_id)"
                        >
                        {{resourcesItem.level_name}}
                    </div>
            </div>
        </div>
             <div class="select-btn">
                <el-button type="primary" @click="StudentFun"  plain>导出学生</el-button>
                <el-button type="primary" @click="importStudent" plain>导入学生</el-button>
                <el-button type="primary" @click="addStudent"><i class="el-icon-plus"></i> 添加学生</el-button>
            </div>
        </div>

        <div class="type-wrapper" style="margin-left: 0;">
            <div class="type-name"></div>
                <div class="type-list" style="margin-left: 10px;">
                   <span style="color: #c0c4cc">(考试等级筛选)</span>
                </div>
        </div>
                
        <el-table :data="resourcesTableData"
                  border
                  style="width: 100%; margin-top: 20px; flex: 1;"
                  height="1%"
                  size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                  :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}"
                  class="table-content">
            <el-table-column label="序号" width="80" align="center"  >
                <template slot-scope="scope">
                     {{ (classifyPages.page - 1) * classifyPages.pageSize + scope.$index + 1 }}
                </template>
            </el-table-column>
            <el-table-column label="照片" align="center">
                <template slot-scope="scope">
                    <div class="column-logo-box">
                        <div class="column-logo">
                        <img v-if="scope.row.avatar" :src="scope.row.avatar" alt="" />
                        <img v-else src="./../../../assets/images/user_img.png" alt="" />
                        </div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="考生姓名" align="center">
                <template slot-scope="scope">
                    <span class="text-overflow-2" :title="scope.row.nickname">{{ scope.row.nickname }}</span>
                </template>
            </el-table-column>
            <el-table-column label="身份证号"  align="center" width="200">
                <template slot-scope="scope">
                    <span>{{ scope.row.id_number }}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column label="考场"  align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.examination_room_name }}</span>
                </template>
            </el-table-column> -->
            <el-table-column label="账号"  align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.username }}</span>
                </template>
            </el-table-column>
            
            <el-table-column label="操作" width="200" align="center">
                <template slot-scope="scope">
                    <el-link type="success" :underline="false" @click="editResources(scope.row)">编辑</el-link>
                    <el-link type="danger" :underline="false" @click="deleteRow(scope.row)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>

    <!--   添加编辑学生dialog   -->
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        :title="dialogTitle"
        custom-class="green-dialog"
        :visible.sync="studentDialogVisible"
        width="500px"
        @close="resetForm()"
        @opened="openJudge"
        >
      <el-form ref="addStudentForm" :model="addStudentForm" label-width="80px" :rules="addStudentRules" label-position="top" >
        <el-form-item label="学生名称" prop="name" >
          <el-input v-model="addStudentForm.name" maxlength='10' ref="cursorInput" placeholder="必须填写真实姓名" clearable></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="card" >
          <el-input v-model="addStudentForm.card"  maxlength='18' ref="cursorInput" placeholder="必须填写有效证件号码" :disabled="disabled" clearable></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="rank">
          <el-select v-model="addStudentForm.gender" placeholder="请选择" style="width: 100%" >
            <el-option v-for="item in genderList" :key="item.id" :label="item.name" :value="item.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考级" prop="rank">
          <el-select v-model="addStudentForm.rank_id" placeholder="请选择" style="width: 100%" >
            <el-option v-for="item in rankList" :key="item.grade_id" :label="item.level_name" :value="item.grade_id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="学生分类" prop="classify">
          <el-select v-model="addStudentForm.classify_id" placeholder="请选择" style="width: 100%">
            <el-option v-for="item in classifyList" :key="item.testee_category_id" :label="item.name" :value="item.testee_category_id"></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="选择考场" prop="place">
          <el-select v-model="addStudentForm.place_id" placeholder="请选择" style="width: 100%" @change="placeFun" >
            <el-option v-for="item in placeList" :key="item.room_id" :label="item.examination_room_name" :value="item.room_id" ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="考场时间" prop="time_id">
            <el-input v-model="addStudentForm.time_id" ref="cursorInput" placeholder="考试时间" clearable></el-input>
        </el-form-item> -->
        <el-form-item label="账号" prop="user" >
            <el-input v-model="addStudentForm.user" ref="cursorInput" placeholder="请填写账号" :disabled="disabled" clearable></el-input>
        </el-form-item>
          <el-form-item :label="addStudentForm.testee_id?'重置密码':'填写密码'" prop="password">
            <el-input v-model="addStudentForm.password" maxlength='10' ref="cursorInput" :placeholder="addStudentForm.testee_id?'请重置密码':'请填写密码'" clearable></el-input>
        </el-form-item>
        <el-form-item label="上传头像" style="width: 100px">
          <div class="logo-src-box" v-if="addStudentForm.avatar">
            <img :src="logoSrc" alt="" />
            <i @click="clearLogo" class="iconfont">&#xe651;</i>
          </div>
          <el-upload
            class="logo-uploader"
            v-else
            action="school/upload"
            name="avatar"
            :headers="headersParams"
            :show-file-list="false"
            :on-success="handleCoverSuccess"
            :before-upload="beforeCoverUpload"
          >
            <i class="el-icon-plus logo-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <span class="school-prompt"
          >建议尺寸：一寸照片；格式为.jpg或.jpeg或.png；大小不超过2M的图片</span
        >
      </el-form>
      <div class="dialog-footer">
        <el-button @click="studentDialogVisible = false">取 消</el-button>
        <el-button style="margin-left: 30px" type="primary" @click="addfrom('addStudentForm')">确 定</el-button>
      </div>
    </el-dialog>
        <!--  导入学生弹框  -->
    <el-dialog
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        title="导入学生"
        custom-class="student-dialog"
        :visible.sync="importDialogVisible"
        width="500px">
       <el-button @click="downloadTemplate">下载模板</el-button>
      <div class="dialog-footer">
        <el-button  type="primary" @click="confirmImport()">导入文件</el-button>
        <el-button  @click="importDialogVisible = false">取 消</el-button>
        <input type="file" ref="excelInput" accept=".xlsx" title="" @change="changeExcel($event)" style="display: none;">
      </div>
    </el-dialog>
    <el-pagination
      class="pager-center"
      style="margin: 20px 0 30px; text-align: center"
      :current-page="classifyPages.page"
      :page-size="classifyPages.pageSize"
      :total="classifyPages.total"
      layout="prev, pager, next, jumper"
      @current-change="classifyChange"
    >
    </el-pagination>
    </div>
</template>

<script>
import {
  studentClassificationData,
  studenttime,
  studentlevel,
  examineeList,
  addexaminee,
  particularsList,
  delstudentLis,
  download,
  leadStudent,
  deriveStudent
} from "@/utils/apis.js";
    export default {
        name: "Index",
        data(){
        // 身份证验证
            const checkIDCard = (rule, value, callback) => {
            const IDCardReg = /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[0-2])(([0-2][1-9])|10|20|30|31)\d{3}(\d|X|x)$/
            // const sfzhReg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
            if (value) {
                if (IDCardReg.test(value)) {
                callback()
                } else {
                callback(new Error('身份证号格式不正确'))
                }
            } else {
                callback(new Error('请输入身份证号'))
            }
        }
          return{
            //title部分
            examinee_id:'',//院校考生分类
            examineeList:[],//院校考生分类列表
            testTime_id:[], //院校考试时间选择数组
            typeCurrent: 0,
            logoSrc: "",     
            headersParams: {
                 Authorization: localStorage.getItem("evaluationToken") || "",
            },
            hostUrl: localStorage.getItem("hosturl") || "",
            //考试筛选列表    
            resourcesTypeList:[],
            //表格列表
            resourcesTableData:[],
            //列表分页
            classifyPages: {
                page: 1,
                pageSize: 10,
                total: 0,
            },

            //性别
            genderList:[
                 {
                    id:1,
                    name:"男"
                },
                {
                    id:0,
                    name:"女"
                }
            ],
            //弹窗部分
            //添加学生弹窗列表
            studentDialogVisible: false,
            dialogTitle: '',
            addStudentForm: {
              name:'', //学生姓名
              card:'', //身份证号
              rank_id: '',//考级选择
              classify_id:'',//学生分类选择
              place_id:'', //考场选择
              time_id:'', //考试时间选择
              user:'', //账号
              password:'', //密码
              avatar:'', //图片
              testee_id:'',//编辑ID
              gender:'' //性别
            },
            //导入学生
             importDialogVisible:false,
             //导入模板地址
             site:'',
             //鉴定中心ID
             check_center_id:'',
             //输入框是否禁止
             disabled:false,

             formDatas:'',

            //考级列表
            rankList:[], 
            classifyList:[], //学生分类列表   
            placeList:[], //选择考场列表
            timeList:[], //选择考试时间列表

            //开始开始时间
            examination_start_time:'',
            //考试结束时间
            examination_end_time:'',

            //验证规则
            addStudentRules: {
                name: [
                {min: 2, required: true, message: '请输入学生名称2-10个字符', trigger: 'blur'},
                ],
                card: [
                { min: 1, max: 18,required: true, validator: checkIDCard, trigger: 'blur' }
                ],
                user: [
                { min: 3,max:30,required: true, message: '请输入账号3-30个字符' }
                ],
                password: [
                { min: 6, max: 12,required: true, message: '请重置密码6-12字符' }
                ],
            },
      

          }

        },
         mounted() {
            this.gainStudent();
            this.gainStudentlevel();
            this.getStudenList();
            // this.getstudenttime();
            this.downloadmode();
            // this.StudentFun()
         },

        methods: {
        //选择的资源类型
		toggleTypeCur(id) {
			this.typeCurrent = id;
			this.classifyPages.page = 1
            this.getStudenList()
		},

        //获取考试等级
        gainStudentlevel(){
            studentlevel()  
                .then((res) => {              
                this.rankList = res.data
                this.resourcesTypeList = res.data
                })
                .catch((err) => {
                console.log("err", err);
            });
        },
        
        //获取考生分类
        gainStudent(){
            let params = {
                paging: 0,
            };
            studentClassificationData(params)
                .then((res) => {
                //   console.log(res)
                 this.classifyList = res.data.list;
                 this.examineeList = res.data.list;
                })
                .catch((err) => {
                console.log("err", err);
            }); 
        },

        //考生分类改变
        examineeCha(val){
            this.examinee_id = val
            this.classifyPages.page = 1
            this.getStudenList()
        },

         // 导入学生弹窗
        importStudent(){
            this.importDialogVisible = true;
        },


        //导入文件
        confirmImport(){
            this.$refs.excelInput.value = null
            this.$refs.excelInput.click();
        },

        //上传文件
          changeExcel(event){
              if (event.target.files.length === 0) return;
              let formData = new FormData()
              formData.append('file',event.target.files[0])
              leadStudent(formData).then((res)=>{
                this.$message({
                message:res.msg,
                type:'success',
                duration:1000,
                onClose:()=>{
                    this.importDialogVisible = false;
                    this.getStudenList();
                }
                })
            }).catch((err)=>{
                console.log('err', err)
            })
          },

        //下载模板请求
        downloadmode(){
            download()
             .then((res) => {
            //    console.log(res,"导入地址")
               this.site = res.data.url
                })
                .catch((err) => {
                console.log("err", err);
            });
        },

         // 下载模板
        downloadTemplate(){
            window.open(this.site)
        },

        //导出学生请求
        StudentFun(){
            this.check_center_id = localStorage.getItem('check_center_id')
            window.location.href = localStorage.getItem('hosturl') + "testee/exportTestee?check_center_id="+this.check_center_id;
            // console.log(localStorage.getItem('check_center_id'))
        },

        //获取考生管理列表
        getStudenList(){
            let params = {
                paging:1, 
                page:this.classifyPages.page,
                pageSize:this.classifyPages.pageSize,
                testee_category_id:this.examinee_id, //考生分类ID
                examination_start_time:this.examination_start_time,//考试开始时间
                examination_end_time:this.examination_end_time,//考试结束时间
                grade_id:'' //考级ID
            }
           if (this.typeCurrent) {
				params.grade_id = this.typeCurrent;
			}
            examineeList(params)
              .then((res) => {   
                 this.resourcesTableData = res.data.list
                 this.classifyPages.total = res.data.total
                })
                .catch((err) => {
                console.log("err", err);
            });     
        },

        //获取考场和考试时间
        // getstudenttime(){
        //     studenttime()
        //         .then((res) => {              
        //         this.placeList = res.data;
        //         //  console.log(res.data)
        //         })
        //         .catch((err) => {
        //         console.log("err", err);
        //     });
        // },

        //添加学生
        addStudent() {
            this.dialogTitle = '添加学生';
            this.studentDialogVisible = true;
            this.addStudentRules.password = [{ min: 6, max: 12,required: true, message: '请重置密码6-12字符' }]
        },
         //编辑学生
        editResources(row){
            this.studentDialogVisible = true;
            this.dialogTitle = "编辑学生";
            this.addStudentForm.testee_id = row.testee_id   
            
        },

        //打开弹窗回调
        openJudge(){    
          if (this.addStudentForm.testee_id) {  
            
              let params = {
                testee_id: this.addStudentForm.testee_id,
              };
            particularsList(params)
            .then((res)=>{
                this.addStudentForm.name = res.data.nickname
                this.addStudentForm.card = res.data.id_number
                this.addStudentForm.user = res.data.username
                this.addStudentForm.avatar = res.data.avatar;
                this.logoSrc = res.data.avatar;
                this.addStudentForm.rank_id = res.data.grade_id;
                this.addStudentForm.classify_id = res.data.testee_category_id;
                if(res.data.testee_category_id == 0){
                    this.addStudentForm.classify_id = ''
                }
                this.addStudentForm.place_id = res.data.room_id;           
                this.addStudentForm.gender = res.data.gender;
                this.disabled = true 
                this.placeList.forEach((item) => {
                    if(this.addStudentForm.place_id == item.room_id ){
                       this.addStudentForm.time_id = item.examination_start_time + " - " + item.examination_end_time
                    }
                })
                this.addStudentRules.password = []
            })
            .catch((err) => {
                console.log("err", err);
            });
          }
        },

        //添加学生确定按钮
        addfrom(formName){   
           this.$refs[formName].validate((valid) => {
             if (valid) {
                   let params = {
                       nickname:this.addStudentForm.name, //学生姓名
                       username:this.addStudentForm.user,  //账号
                       password:this.addStudentForm.password, //密码
                       id_number:this.addStudentForm.card, //身份证
                       grade_id:this.addStudentForm.rank_id, //考级ID
                       testee_category_id:this.addStudentForm.classify_id, //学生分类ID
                       room_id:this.addStudentForm.place_id, //考场ID
                       avatar:this.addStudentForm.avatar, //头像
                       testee_id:'', //考生ID
                       gender:this.addStudentForm.gender //性别
                   }
                    //编辑
                    if (this.addStudentForm.testee_id) {
                        params.testee_id = this.addStudentForm.testee_id                 
                    }
                   addexaminee(params)
                   .then((res)=>{
                        this.$message.success(res.msg);
                        this.studentDialogVisible = false;
                        this.getStudenList()
                   })
                    .catch((err) => {
                        console.log("err", err);
                    });
               }
           })
        },

        //添加学生选择考场改变事件
        // placeFun(){
        //     for(let i=0; i<this.placeList.length;i++){
        //         this.addStudentForm.time_id = this.placeList[i].examination_start_time+' - '+this.placeList[i].examination_end_time
        //     }   
        // },

        //选择考试时间
        // time(){
        //       if(this.testTime_id != null){
        //           this.examination_start_time = JSON.stringify(new Date(this.testTime_id[0]).getTime()).substring(0,10),
        //           this.examination_end_time = JSON.stringify(new Date(this.testTime_id[1]).getTime()).substring(0,10) 
        //            this.classifyPages.page = 1
        //        }else{
        //            this.testTime_id=[]
        //            this.examination_start_time = ''
        //            this.examination_end_time = ''
        //        }
        //      this.getStudenList()
        // },

        // 上传图片
        handleCoverSuccess(res, file) {
            if (res.code === 200) {
                this.logoSrc = this.hostUrl + res.data.src; // 有域名
                this.addStudentForm.avatar = res.data.src; //无域名
                this.$message.success("头像上传成功");
                console.log(res)
            } 
            // else {
            //     this.$message.error("头像上传失败，请稍后再试~");
            // }
        },
        beforeCoverUpload(file) {
            const isLt2M = file.size/1024/1024 <2
            const isImg =
                file.name.toLowerCase().endsWith(".jpg") ||
                file.name.toLowerCase().endsWith(".jpeg") ||
                file.name.toLowerCase().endsWith(".png");
            if (!isImg) {
                this.$message.error("上传图片只能是 jpg 或 jpeg 或 png 格式!");
                return false;
            }else if(!isLt2M){
                  this.$message.error("上传图片大小不能超过2M！");
            }
            return isImg;
            return isLt2M;
        },

        //清除新建表单
        resetForm() {
            this.$refs.addStudentForm.resetFields();
            this.addStudentForm.name = ''
            this.addStudentForm.user = ''
            this.addStudentForm.password = ''
            this.addStudentForm.card = ''
            this.addStudentForm.rank_id = ''
            this.addStudentForm.classify_id = ''
            this.addStudentForm.place_id = ''
            this.addStudentForm.avatar = ''
            this.addStudentForm.testee_id = ''
            this.addStudentForm.gender = ''
            this.disabled = false
        },

          // 删除头像
        clearLogo() {
            this.logoSrc = null;
            this.addStudentForm.avatar = "";
        },

         //分页改变事件
        classifyChange(val) {
            this.classifyPages.page = val;
            this.getStudenList();
        },

        //删除列表
        deleteRow(row) {
            this.$confirm(`是否删除该条考生信息`, "删除", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                customClass: "green-message-box",
                type: "warning",
            })
            .then(() => {
                let params = {
                    testee_id: row.testee_id,
                };
               
                delstudentLis(params).then((res) => {
                    if (res.code === 200) {
                    this.$message({
                        type: "success",
                        message: "删除成功",
                    });
                     //分页只有一条数据删除之后为空的处理
                if((this.classifyPages.total-1)%this.classifyPages.pageSize===0){
                    this.classifyPages.page = this.classifyPages.page-1
                }
                    this.getStudenList();
                    }
                });
            })
            .catch(() => {
                this.$message({
                    type: "info",
                    message: "已取消删除",
                });
            });
        },
        }
        
    }
</script>
<style lang="scss" scoped>
    .resources-content {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;
    }

    .select-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        .select-left {
            .text {
                font-size: 14px;
                color: #333333;
                margin-right: 10px;
            }
            .resources-cascader {
                width: 420px;
                ::v-deep .el-input__inner {
                    border-radius: 0;
                }
            }
        }
        .select-btn {
            i {
                font-size: 12px;
                margin-right: 5px;
            }
        }
    }
    
    .type-wrapper {
        display: flex;
        align-items: center;
        margin-left: 20px;
        .type-name {
            width: 100px;
            line-height: 30px;
            color: #333;
        }
        .type-list {
            .type-item {
                display: inline-block;
                padding: 4px 10px;
                border-radius: 2px;
                font-size: 14px;
                border: 1px solid #D2D2D2;
                background-color: #fff;
                cursor: pointer;
                color: #666;
                transition: all .3s ease-in;
                margin-right: 10px;
                &.current {
                    color: #fff;
                    background: #2DC079;
                    border: 1px solid #2DC079;
                }
                &:hover {
                    background: #1AB163;
                    border: 1px solid #1AB163;
                    color: #fff;
                }
            }
        }
    }
    .select-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .select-left {
            .text {
                font-size: 14px;
                color: #333333;
                margin-right: 10px;
            }
            .resources-cascader {
                width: 420px;
                ::v-deep .el-input__inner {
                    border-radius: 0;
                }
            }
        }
        .select-btn {
            i {
                font-size: 12px;
                margin-right: 5px;
            }
        }
    }
      .logo-uploader {
    ::v-deep.el-upload {
      width: 80px;
      height: 80px;
      background: #eeeeee;
      display: flex;
      align-items: center;
      justify-content: center;
      .logo-uploader-icon {
        font-size: 32px;
        color: #a0a0a0;
      }
    }
  }
  .column-logo-box {
    .column-logo {
      width: 48px;
      height: 48px;
      border: 1px solid #eeeeee;
      border-radius: 10px;
      overflow: hidden;
      display: flex;
      box-sizing: content-box;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .logo-src-box {
    display: flex;
    width: 80px;
    height: 80px;
    box-sizing: border-box;
    border: 1px solid #EEEEEE;
    position: relative;
    img {
      max-width: 100%;
      max-height: 100%;
    }

    i {
      line-height: 1;
      font-size: 14px;
      color: #dcdcdc;
      position: absolute;
      right: -20px;
      top: 0;
      cursor: pointer;
    }
  }
    .school-prompt {
    color: #999;
    font-size: 12px;
    width: 200px;
    height: 200px;
  }
  .dialog-footer{
        margin-top: 20px;
  }
  .block{
      display: inline-block;
  }
</style>
